import React from 'react';

import { useRouter } from 'next/router';
import Head from 'next/head';
import getConfig from 'next/config';

import PropTypes from 'prop-types';

const { publicRuntimeConfig } = getConfig();

const { MIDTRANS_CLIENT_KEY, MIDTRANS_ENV, MIDTRANS_CLIENT_SNAP, APP_ENV } = publicRuntimeConfig;

const blackListedRoutesForIndexing = [
  '/plans',
  '/accounts',
  '/payment-link',
  '/application',
  '/checkout',
  '/search',
  '/agents',
  '/benefits',
  '/verify-email',
  '/asuransi-bisnis',
  '/thank-you',
  '/payment-invalid',
  '/penawaran',
  '/compare',
];

const Meta = props => {

  const route = useRouter();

  const isBlackListedRoute = (pathName) => {
    return blackListedRoutesForIndexing.some(blackListedRoute => pathName.startsWith(blackListedRoute));
  };

  const isBengkel = route.asPath === '/bengkel-mobil/'

  return (
    <Head>
      <title>{props.title}</title>
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no"
      />
      <meta name="theme-color" content="#1D66DD" />
      <meta name="mobile-web-app-capable" content="yes" />
      {
        (APP_ENV !== 'production' || isBlackListedRoute(route.pathname)) && (
          <meta name="robots" content="noindex, nofollow" />
        )
      }
      {props.meta}
      {props.isPayment && !isBengkel && (
        <>
          <script
            id="midtrans-script"
            type="text/javascript"
            src="https://api.midtrans.com/v2/assets/js/midtrans-new-3ds.min.js"
            data-environment={MIDTRANS_ENV}
            data-client-key={MIDTRANS_CLIENT_KEY}
          />
          <script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/picomodal/3.0.0/picoModal.js" />
          <script
            type="text/javascript"
            src={MIDTRANS_CLIENT_SNAP}
            data-client-key={MIDTRANS_CLIENT_KEY}
          />
          <script type="text/javascript" src="https://js.xendit.co/v1/xendit.min.js" />
        </>
      )}
      <script type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({ "@context": "https://schema.org/", "@type": "WebSite", "name": "Lifepal: Broker Asuransi No. 1 di Indonesia", "description": "Bandingkan berbagai pilihan polis asuransi terbaik di Indonesia secara online di Lifepal. Dapatkan promo menarik dan premi paling terjangkau!", "url": "https://lifepal.co.id/", "potentialAction": { "@type": "SearchAction", "target": "https://lifepal.co.id/search/?s={search_term_string}", "query-input": "required name=search_term_string" } })
        }}
      />
      <script type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html:
            JSON.stringify({ "@context": "https://schema.org", "@type": "Organization", "name": "Lifepal.co.id", "url": "https://lifepal.co.id/", "logo": "https://lifepal.co.id/static/images/favicon/favicon.ico", "sameAs": ["https://www.facebook.com/lifepal", "https://twitter.com/lifepal", "https://www.instagram.com/lifepal/", "https://www.youtube.com/c/@lifepalmedia", "https://www.linkedin.com/company/lifepalcoid/", "https://lifepal.co.id/"] })
        }}
      />
    </Head>
  )
};

Meta.propTypes = {
  title: PropTypes.string.isRequired,
  isPayment: PropTypes.bool,
  meta: PropTypes.node
};

Meta.defaultProps = {
  title: 'Default Title'
};

export default Meta;
